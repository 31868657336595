// Colors
$orange: #ed822a;
//$warning: #fd7e14;
//$dark: #343c49;

//$light: #f4f6fa !default;

$primary: $orange;
//$secondary: #343c49;
//$dark: darken($secondary, 10%);

$page-background-color: #f4f6fa;

// Sizes
$navbar-size: 50px;
$sidebar-size: 175px;
