@import '_variables';
@import '~@tabler/core/src/scss/tabler';
@import '~react-toastify/dist/ReactToastify.css';

body {
  background-color: $light;
  //overflow-x: hidden;
}

body,
#root {
  height: 100%;
}

.pointer {
  cursor: pointer !important;
}

.position-fixed {
  position: fixed !important;
}

.navbar-push {
  margin-top: $navbar-size;
}

.sidebar-push {
  margin-left: $sidebar-size;
}

// Remove ugly glow from bootstrap buttons
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none !important;
  box-shadow: none !important;
}

// Tabler overrides
.nav-link-icon {
  width: auto !important;
}

// Dark theme fixes

.theme-dark .text-black-50 {
  color: #c1c1c1 !important;
}

.theme-dark .pkg-name {
  color: #e2e2e2 !important;
}

.theme-dark .link-dark {
  color: #e2e2e2;
}

.theme-dark .list-group-item {
  border-color: rgba(101, 109, 119, 0.16);
}

.theme-dark .list-group-item-action:hover,
.list-group-item-action:focus {
  background-color: rgba(244, 246, 250, 0.05) !important;
}

// fix bug on package list dropdown to be behind other elements on hover
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1000 !important;
}

// Headway widget
#HW_widget_component_HW_container {
  user-select: none;
}

.HW_badge_cont {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 22px !important;
  height: 20px !important;
}

.HW_softHidden {
  opacity: 0 !important;
}

.HW_badge {
  background: $orange !important;
  color: transparent !important;
  height: 6px !important;
  left: unset !important;
  //opacity: 0!important;
  right: 0 !important;
  top: 0 !important;
  width: 6px !important;
}

// react tostify
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #ed822a;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 44px;
  --toastify-toast-max-height: 500px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #ed822a;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: #ed822a;
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

// used only when developing
.debug {
  border: 1px solid red;
}

/* Ads
   ========================================================================== */
.ad_between_packages {
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.ad_below_package_list {
  width: 100%;
  height: 280px;
  margin: 0 auto;
}

.ad_below_package_share {
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.ad_below_inputbar {
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

//// Small devices (landscape phones, 576px and up)
//@media (min-width: 576px) {
//  .ad_between_packages {
//    width: 100%;
//    height: 90px;
//    margin: 0 auto;
//  }
//
//  .ad_below_package_list {
//    width: 506px;
//    height: 280px;
//    margin: 0 auto;
//  }
//
//  .ad_below_package_share {
//    width: 506px;
//    height: 100px;
//    margin: 0 auto;
//  }
//}
//
//// Medium devices (tablets, 768px and up)
//@media (min-width: 768px) {
//  .ad_between_packages {
//    width: 100%;
//    height: 90px;
//    margin: 0 auto;
//  }
//
//  .ad_below_package_list {
//    width: 275px;
//    height: 280px;
//    margin: 0 auto;
//  }
//
//  .ad_below_package_share {
//    width: 392px;
//    height: 100px;
//    margin: 0 auto;
//  }
//}
//
//// Large devices (desktops, 992px and up)
//@media (min-width: 992px) {
//  .ad_between_packages {
//    width: 100%;
//    height: 90px;
//    margin: 0 auto;
//  }
//
//  .ad_below_package_list {
//    width: 291px;
//    height: 280px;
//    margin: 0 auto;
//  }
//
//  .ad_below_package_share {
//    width: 602px;
//    height: 100px;
//    margin: 0 auto;
//  }
//}
//
//// X-Large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) {
//  .ad_between_packages {
//    width: 100%;
//    height: 90px;
//    margin: 0 auto;
//  }
//
//  .ad_below_package_list {
//    width: 351px;
//    height: 280px;
//    margin: 0 auto;
//  }
//
//  .ad_below_package_share {
//    width: 720px;
//    height: 100px;
//    margin: 0 auto;
//  }
//}
//
//// XX-Large devices (larger desktops, 1400px and up)
//@media (min-width: 1400px) {
//  .ad_between_packages {
//    width: 100%;
//    height: 100px;
//    margin: 0 auto;
//  }
//
//  .ad_below_package_list {
//    width: 411px;
//    height: 280px;
//    margin: 0 auto;
//  }
//
//  .ad_below_package_share {
//    width: 842px;
//    height: 100px;
//    margin: 0 auto;
//  }
//}

.button_pulse {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 3s;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
